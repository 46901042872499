import * as React from "react"

import * as styles from "./styles.module.css"
import cx from "classnames"
import Footer from "../Footer"
import Menu from "../Menu"
import { infoFooter } from "../../mock/data"

const Dpc: React.FC = () => {
  const gridClasses = cx("m-auto main-container")
  const containerClasses = cx("flex flex-col", global.pagesDPCandPP)
  const captionClasses = cx("text-center white font-bold", styles.captionTag)
  const trClasses = cx("border-t-2")
  const concat = `mailto:${infoFooter.email}`

  return (
    <>
      <div className="bg-elephant text-white font-barlow ">
        <Menu pageName="dpc" />
        <div className={gridClasses}>
          <div className="flex justify-center">
            <div className={containerClasses}>
              <p className="font-bold text-4xl m-0 text-left ">
                COMITÊ DE PROTEÇÃO DE DADOS
              </p>
              <p className="font-light pt-8 m-0 text-justify">
                A <span className="font-bold">ENE SOLUÇÕES</span>, em
                atendimento às disposições do art. 41, da Lei 13.709/2020,
                constitui
                <span className="font-bold"> COMITÊ DE PROTEÇÃO DE DADOS.</span>
              </p>
              <p className="mt-4">
                <span className="font-bold">1.</span> O referido comitê possui
                as atribuições previstas no art. 41, §2.º, da LGPD, sendo:
              </p>
              <p className="">
                <span className="font-bold">a)</span> aceitar reclamações e
                comunicações dos titulares, prestar esclarecimentos e adotar
                providências necessárias para cumprimento do previsto no art.
                18, da LGPD;
              </p>
              <p className="">
                <span className="font-bold">b)</span> orientar os funcionários e
                os contratados da entidade a respeito das práticas a serem
                tomadas em relação à proteção de dados pessoais;
              </p>
              <p className="">
                <span className="font-bold">c)</span> executar as demais
                atribuições determinadas pelo controlador ou estabelecidas em
                normas complementares.
              </p>
              <p className="">
                <span className="font-bold">d)</span> emitir parecer sobre
                resposta ao pedido de esclarecimentos de titulares, autoridades
                e ainda demais entidades vinculadas à gestão de dados.
              </p>
              <p className="">
                <span className="font-bold">e)</span> atender às solicitações da
                Autoridade Nacional de Proteção de Dados.{" "}
              </p>
              <p className="">
                <span className="font-bold">f)</span> outras atribuições que o
                próprio comitê julgar necessárias para exercer de forma completa
                e ampla as suas atribuições.
              </p>
              <p className="m-0">
                <span className="font-bold">2.</span> Foram indicados para
                compor o referido comitê:
              </p>
              <div className="flex justify-center">
                <table className="table-caption border-2 my-3">
                  <caption className={captionClasses}>
                    Integrantes do Comitê
                  </caption>
                  <tr className={trClasses}>
                    <th className="border-r-2">Nome</th>
                    <th>CPF</th>
                  </tr>
                  <tr className={trClasses}>
                    <td className="border-r-2 px-4 text-center">
                      Fernando Valentini Soares
                    </td>
                    <td className="px-4 text-center">086.334.256-61</td>
                  </tr>
                  <tr className={trClasses}>
                    <td className="border-r-2 px-4 text-center">
                      Helton Valentini Soares
                    </td>
                    <td className="px-4 text-center">063.384.546-97</td>
                  </tr>
                </table>
              </div>

              <p>
                <span className="font-bold">3.</span> Todas as questões
                envolvendo os usuários, clientes e titulares de dados poderá ser
                feito pelo telefone{" "}
                <a
                  href="https://api.whatsapp.com/send?phone=553432295811"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-white underline font-bold sm:text-xs md:text-base lg:text-base xl:text-base"
                >
                  {infoFooter.number}
                </a>{" "}
                ou ainda pelo email:{" "}
                <a
                  className="text-white font-bold sm:text-xs md:text-base lg:text-base xl:text-base"
                  href={concat}
                >
                  {infoFooter.email}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Dpc
